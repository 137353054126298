<div class="mat-elevation-z2 scroll">
  <mat-table matTableExporter [matSortActive]="starter" matSortDirection="asc" [dataSource]="dataSource" matSortDisableClear matSort>
    <ng-container *ngFor="let column of columnsData; let i = index" [matColumnDef]="column.name">
      <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isSortable" >
        {{ column.title }}
      </mat-header-cell>
      
      <mat-cell *matCellDef="let element">
        <div *ngIf="i === linkIndex; else elseBlock">
          <div class="mat-cell-title">{{ columnsData[i].title }}</div>
          <a *ngIf="!dialogComponent; else elseDialog" [routerLink]="(baseUrl || '') + element['route']">
            {{ element[column.name] || '--' }}
          </a>
          <ng-template #elseDialog>
            <a style="cursor: pointer;" (click)="openDialog(element)">{{ element[column.name] || '--' }}</a>
          </ng-template>
        </div>
        <ng-template #elseBlock>
          <div class="mat-cell-title">{{ columnsData[i].title }}</div>
          <span>{{ element[column.name] || '--' }}</span>
        </ng-template>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>
