import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { UserCredentials, User, UserData } from '../../interfaces/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user?: User;
  private _idToken: string;
  private logged = false;

  private userSubject: BehaviorSubject<User | undefined> = new BehaviorSubject(undefined);
  readonly userSubscription = this.userSubject.asObservable();

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.auth.onAuthStateChanged((credentials) => {
      if (credentials && !this.user || !this.logged) {
        const { uid, email } = credentials;
        this.logged = true

        this.getAdminUserDoc(uid)
        .then((userData) => {
          this.user = { ...userData, uid, email };
          this.userSubject.next(this.user);
        })
        .catch(error => this.logout());
      };
    })

    this.auth.onIdTokenChanged((credentials) =>  {
      credentials.getIdToken()
        .then(idToken => this._idToken = idToken)
        .catch(console.error);
    });
  }

  get tokenObservable() {
    return this.auth.idToken;
  }

  get idToken() {
    return this._idToken;
  }

  set idToken(token: string) {
    if (!token) {
      return;
    }

    this._idToken = token;
  }

  // private async register(email: string, password: string) {
  //   if (this.logged) {
  //     throw new Error('Already Sign In');
  //   }

  //   const { user: { uid } } = await this.auth.createUserWithEmailAndPassword(email, password);

  //   const userData: UserData = {
  //     firstName: 'Admin',
  //     lastName: 'Admin',
  //     isAdmin: true,
  //     nickName: 'Admin',
  //     phone: '0',
  //     publishedBoards: 0,
  //     publishedCodes: 0,
  //     publishedListings: 0
  //   }

  //   await this.firestore.doc(`Users/${uid}`).set(userData)
  
  //   this.user = { ...userData, uid, email };
  // }

  async login(email: string, password: string) {
    if (this.logged) {
      throw new Error('Already Sign In');
    }

    try {
      const { user: { uid } } = await this.auth.signInWithEmailAndPassword(email, password);
      this.logged = true;
      const user = await this.getAdminUserDoc(uid);
      this.user = { ...user, uid, email }
    } catch (error) {
      if (error.message === 'Usuario Inválido') {
        this.logout();
      }
      throw error;
    }
  }

  private async getAdminUserDoc(uid: string) {
    const doc = await this.firestore
    .doc(`Users/${uid}`)
    .get()
    .toPromise()

    const user = doc.data() as UserData;

    if (!user.isAdmin) {
      throw new Error('Usuario Inválido');
    } 

    return user;
  }

  logout() {
    if (!this.logged) {
      throw new Error('Not Logged User');
    }
    this.logged = false;
    this.userSubject.next(undefined);
    return this.auth.signOut();
  }

  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email)
  }

}
