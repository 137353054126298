import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AppInterceptor } from './intercerptors/app.interceptor';
// import { HttpErrorInterceptor } from './intercerptors/http-error.interceptor';

// import { CoreService } from './services/core.service';
// import { AuthGuard } from './guards/auth.guard';

// import { HeaderComponent } from './components/header/header.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from 'src/environments/environment';
import { AuthService } from './services/firebase/auth.service';
import { DatabaseService } from './services/firebase/database.service';
import { FirestoreService } from './services/firebase/firestore.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './intercerptors/app.interceptor';
import { HttpErrorInterceptor } from './intercerptors/http-error.interceptor';
import { CoreService } from './services/core.service';
import { AuthGuard } from './guards/auth.guard';
import { HeaderComponent } from './components/header/header.component';
import { StorageService } from './services/firebase/storage.service';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    HttpClientModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent
  ],
  providers: [
    AuthService,
    DatabaseService,
    StorageService,
    FirestoreService,
    CoreService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true
    // },
    // CoreService,
  ]
})
export class CoreModule { }
