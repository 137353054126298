import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material.module';
import { TableComponent } from './components/table/table.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { PopUpsService } from './services/pop-ups.service';
import { ImportFileComponent } from './components/import-file/import-file.component';

@NgModule({
  declarations: [
    TableComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    ImportFileComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TableComponent,
    ImportFileComponent
  ],
  providers: [
    PopUpsService
  ]
})
export class SharedModule { }
