import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { map, retry, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable()
export class CoreService {

  constructor(private http: HttpClient) { }

  post<T>(endpoint, body?): Observable<T> {
    return this.http.post<T>(`${env.apiUrl}${endpoint}`, body).pipe(
      map(data => data),
      retry(1),
      catchError(err => of(err))
    );
  }

  put<T>(endpoint, body?): Observable<T> {
    return this.http.put<T>(`${env.apiUrl}${endpoint}`, body).pipe(
      map(data => data),
      retry(1),
      catchError(err => of(err))
    );
  }

  get<T>(endpoint): Observable<T> {
    return this.http.get<T>(`${env.apiUrl}${endpoint}`).pipe(
      map(data => data),
      retry(1),
      catchError(err => of(err))
    );
  }

  delete<T>(endpoint): Observable<T> {
    return this.http.delete<T>(`${env.apiUrl}${endpoint}`).pipe(
      map(data => data),
      retry(1),
      catchError(err => of(err))
    );
  }
  
}
