import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private firestore: AngularFirestore    
  ) { }

  getDoc(path: string) {
    return this.firestore.doc(path).get();
  }

  getCollection(path: string, queryFn?: QueryFn<firebase.firestore.DocumentData>) {
    return this.firestore.collection(path, queryFn).get();
  }

  set(path: string, data: any) {
    return this.firestore.doc(path).set(data);
  }

  delete(path: string) {
    return this.firestore.doc(path).delete();
  }

}
