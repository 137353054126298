<header [ngClass]="{'active': session}">
  <!-- <div class="logo">
    <a [routerLink]="['/']">
      <img src="assets/images/logo.png" alt="Subtle Alliance" width="120px">
    </a>
  </div> -->
  <div *ngIf="session" class="user">
    <button mat-button [matMenuTriggerFor]="menu">
      {{ session?.firstName }} {{ session?.lastName }} 
      <mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="logOut()">Logout</button>
    </mat-menu>
  </div>
</header>
