import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storageSvc: AngularFireStorage
  ) { }

  upload(file: File, path: string) {
    return this.storageSvc.upload(path, file);
  }

  uploadBase64(base64: string, path: string) {
    return this.storageSvc.ref(path).putString(base64, 'base64');
  }

  download(path: string) {
    return this.storageSvc.ref(path).getDownloadURL();
  }

  delete(path: string) {
    return this.storageSvc.ref(path).delete();
  }

  get(path: string) {
    return this.storageSvc.ref(path).getDownloadURL();
  }
}
