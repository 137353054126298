// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://us-central1-go-306618.cloudfunctions.net/',
  firebaseConfig: {
    apiKey: 'AIzaSyBqc2XYnkU4IxIIl6iq_pYeS35_eVTtVN0',
    authDomain: 'go-306618.firebaseapp.com',
    databaseURL: 'https://go-306618-default-rtdb.firebaseio.com',
    projectId: 'go-306618',
    storageBucket: 'go-306618.appspot.com',
    messagingSenderId: '3067793603',
    appId: '1:3067793603:web:2216bd382cf1ddef7a9276'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
