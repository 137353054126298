import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/firebase/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  session: any = null;
  idTokenSubscription: Subscription;

  constructor(
    private router: Router,
    private authSvc: AuthService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.idTokenSubscription = this.authSvc.userSubscription
          .subscribe((user) => {
            this.session = user;
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.idTokenSubscription) {
      this.idTokenSubscription.unsubscribe();
    }
  }

  logOut(): void {
    this.authSvc.logout().then(() => {
      this.session = null;
      this.router.navigate(['/auth/sign-in']);
    });
  }


}
