import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(
    private database: AngularFireDatabase
  ) { }

  get timestamp() {
    return firebase.default.database.ServerValue.TIMESTAMP;
  }

  autoIncrement(amount = 1) {
    return firebase.default.database.ServerValue.increment(amount);
  }

  get(path: string) {
    return this.database.database.ref(path).get();
  }

  getRef(path: string) {
    return this.database.database.ref(path);
  }

  set(path: string, value: any) {
    return this.database.database.ref(path).set(value);
  }

  update(path: string, value: any) {
    return this.database.database.ref(path).update(value);
  }

  push(path: string, value?: any) {
    return this.database.database.ref(path).push(value);
  }

  delete(path: string) {
    return this.database.database.ref(path).remove();
  }

}
