import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/firebase/auth.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private authSvc: AuthService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!this.authSvc.idToken) {
      this.router.navigate(['/auth/sign-in']);
      return false;
    }

    return true;
  }

  canLoad(): boolean {
    if (!this.authSvc.idToken) {
      this.router.navigate(['/auth/sign-in']);
      return false;
    }

    return true;
  }
  
}

