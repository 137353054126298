import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ExportTable, ColumnData } from '../../interfaces/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit {

  displayedColumns: string[];
  dataSource: MatTableDataSource<T>;
  starter: string;

  @Output() isExporting = new EventEmitter<boolean>();
  @Output() isImporting = new EventEmitter<boolean>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('exporter', { static: true }) exporter: any;

  @Input() exportTable: ExportTable;
  @Input() linkIndex: number;
  @Input() columnsData: ColumnData[];
  @Input() clickFunction = () => { };
  @Input() dialogComponent: any;
  @Input() baseUrl: string;

  @Input() set elementData(elements: T[]) {
    this.dataSource = new MatTableDataSource(elements);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  @Input() set filter(event: Event) {
    if (event) {
      this.applyFilter(event);
    }
  }

  @Input() set import(isImporting: boolean) {
    if (isImporting) {
      this.importToTable();
    }
  }

  constructor(
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.displayedColumns = this.columnsData.map(column => column.name);
    const starter = this.columnsData.find(({starting}) => starting);
    if (starter) {
      this.starter = starter.name;
    }
  }

  importToTable(): void {
    setTimeout(() => this.isImporting.emit(false), 2000);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(column) {
    this.matDialog.open(this.dialogComponent, { data: column })
  }

}
