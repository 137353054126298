import { Injectable } from '@angular/core';
import { InformationDialogComponent } from '../components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PopUpsService {

  constructor(private dialog: MatDialog) { }

  public information(error: boolean, message: string, header?: string): MatDialogRef<InformationDialogComponent, any> {
    return this.openDialog<InformationDialogComponent>(InformationDialogComponent, {error, message, header});
  }

  public confirmation(header: string, message: string): MatDialogRef<ConfirmationDialogComponent, any> {
    return this.openDialog<ConfirmationDialogComponent>(ConfirmationDialogComponent, {header, message});
  }

  private openDialog<T>(dialog: any, data: object): MatDialogRef<T, any> {
    return this.dialog.open(dialog, {data});
  }

}
