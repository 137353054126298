import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/firebase/auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private authSvc: AuthService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let jwt = this.authSvc.idToken;
    if (!jwt) {
      next.handle(req);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: jwt
    });

    const request = req.clone({ headers });
    return next.handle(request)
      // .pipe(
      // tap(evt => {

      //   if (evt instanceof HttpResponse) {
      //     const token = evt.headers.get('X-Token');
      //     if (token) {
      //       jwt = token;
      //       this.authSvc.idToken = jwt;
      //     }
      //   }
      // })
    // );
  }

}
