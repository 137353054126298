import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnData } from '../../interfaces/table';

/* 3 MB = 3145728 Bytes (in binary) */
const FILE_MAX_SIZE = 3145728

@Component({
  selector: 'app-import-file',
  template: `
    <input
      #docInput
      hidden
      type="file"
      [name]="inputName"
      (change)="handle($event)"
      accept=".csv,text/csv,application/csv">
    <button
      mat-stroked-button
      color="primary"
      (click)="docInput.value='';docInput.click()">
      {{ btnText }}
    </button>
  `
})
export class ImportFileComponent {

  @Input() btnText:   string;
  @Input() inputName: string;

  @Output() response = new EventEmitter<Promise<any>>();


  handle(event): void {

    const promise = new Promise((resolve, reject) => {
      try {

        if (
          !event.target.files &&
        !event.target.files.length
        ) return reject('Ningun archivo seleccionado.');

        const reader = new FileReader()
        const file   = event.target.files[0]

        reader.addEventListener('load', (e) => {
          resolve(e.target.result);
        });
        // reader.readAsDataURL(file);

        reader.readAsText(file)
        // reader.readAsDataURL(file)
        // reader.onload = async () => resolve(file);

      } catch (error) {
        reject(error)
      }
    });

    this.response.emit(promise);
  }

}
